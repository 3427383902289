/*!
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

/* Scriptumos stílus osztályok */

@import 'utils';
@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:light,regular,bold,semi-bold,italic&subset=latin,latin-ext');

/************ <COMMON> *****************/
html {
  box-sizing: border-box;
  /*font-size: 62.5%;*/
  position: relative;
  min-height: 100%;
}

html,
body {
  margin: 0;
  padding: 0;
  //height: 100%;
  font-size:16px;
  color: $text-primary;
}

body {
  margin: 0 0 625px;
  .remark-form
  {
    bottom: 75px;
  }
}

@media only screen and (min-width : 280px)
{
  body {
    margin: 0 0 520px;
    .remark-form
    {
      bottom: 75px;
    }
  }
}
@media only screen and (min-width : 300px)
{
  body {
    margin: 0 0 480px;
  }
}
@media only screen and (min-width : 350px)
{
  body {
    margin: 0 0 410px;
  }
}
@media only screen and (min-width : 400px)
{
  body {
    margin: 0 0 370px;
  }
}
@media only screen and (min-width : 500px)
{
  body {
    margin: 0 0 340px;
  }
}
@media only screen and (min-width : map-get($grid-breakpoints, sm))
{
  body {
    margin: 0 0 290px;
    .remark-form
    {
      bottom: 50px;
    }
  }
}
@media only screen and (min-width : map-get($grid-breakpoints, md))
{
  body {
    margin: 0 0 290px;
  }
}
@media only screen and (min-width : map-get($grid-breakpoints, lg))
{
  body {
    margin: 0 0 290px;
  }
}
@media only screen and (min-width : map-get($grid-breakpoints, xl))
{
  body {
    margin: 0 0 290px;
  }
}




//@media only screen and (max-width : 320px)
//{
//  body {
//    margin: 0 0 480px;
//  }
//}
//
//@media only screen and (max-width : 300px)
//{
//  body {
//    margin: 0 0 700px;
//  }
//}

.border-radius {
  border-radius: 5px;
}

.page-center-content {
  @media only screen and (min-width : map-get($grid-breakpoints, lg)) {
    width: 100%*10/12;
    margin: 0 auto;
  }
}

@media only screen and (min-width : map-get($grid-breakpoints, sm)) {
  .w-sm-100 {
    width: 100%;
  }
  .w-sm-100-none {
    width: auto;
  }
}
@media only screen and (min-width : map-get($grid-breakpoints, md)) {
  .w-md-100 {
    width: 100%;
  }
  .w-md-100-none {
    width: auto;
  }
}
@media only screen and (min-width : map-get($grid-breakpoints, lg)) {
  .w-lg-100 {
    width: 100%;
  }
  .w-lg-100-none {
    width: auto;
  }
}
@media only screen and (min-width : map-get($grid-breakpoints, xl)) {
  .w-xl-100 {
    width: 100%;
  }
  .w-xl-100-none {
    width: auto;
  }
}


html,
body,
button,
input,
optgroup,
select,
textarea {
  font-family: 'Source Sans Pro', Arial;

}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.dimmer {
  background: rgba(0,0,0,.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    width: 160px;
    height: 160px;
    margin: auto;
    text-align: center;
    padding-top: 20px;

    color: white;

    background-color: rgba(0,0,0,0.5);
    border-radius: 10px;

    .dimmer_text {
      padding-top: 20px;
    }
  }

  .content i {
    margin-bottom: 5px;
    width: 100%;
  }
}

.newBuyOffer-data {
  .radius-b-none {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .radius-t-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .radius-b-none {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .radius-r-none {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .radius-l-none {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .border-t-none {
    border-top: none !important;
  }

  .border-r-none {
    border-right: none !important;
  }

  .border-b-none {
    border-bottom: none !important;
  }

  .border-l-none {
    border-left: none !important;
  }
}


.ui-button,.ui-widget-content .ui-button, .ui-widget-header .ui-button {
  &,&:hover{
    color:$text-primary-light;
    padding: 7px 5px;
    font-size: 16px;
    line-height:22px;
    border-radius: 5px;
  }
  &.orange {
     &{
       background: $otp-orange;
       border-color: $otp-orange;
     }
     &:hover, &:focus {
       background: $otp-orange-hover !important;
       border-color: $otp-orange-hover !important;
     }
   }
  &, &.green {
     &{
       background: $otp-green;
       border-color: $otp-green;
     }
     &:hover, &:focus {
       background: $otp-green-hover !important;
       border-color: $otp-green-hover !important;
     }
   }
  &.white {
    &{
      background: white;
      border-color: $otp-green;
      border-with: 2px;
      color: $otp-green;
    }
    &:hover, &:focus {
      background: $otp-green-hover !important;
      border-color: $otp-green-hover !important;
    }
  }
}
.ui-widget-content .green-link {
  color: $otp-green;
}

.ui-dropdown {
  color: $text-primary-light;
  font-size: 16px;
  line-height:22px;
  border-radius: 4px;
  border: 1px solid $grey-sep-border;
}

.ui-chkbox-container {
  &:focus {
    outline: none;
  }
}

.ui-radiobutton-conatiner {
  position: relative;
}

.ui-radiobutton {
  position: absolute;
  margin-top: 3px;
  &:focus {
    outline: none;
  }
}

.ui-radiobutton-label {
  padding-left: 25px;
}

.ui-radiobutton-box.ui-state-active {
  background-color: $otp-green;
  border-color: $otp-green;
}

.form-group {
  .ui-dropdown {
    width: 100%;
    label {
      height: 36px;
      color: #495057;
    }
  }
}

h1 {
  font-size: 42px;
}
h2 {
  font-size: 34px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 20px;
}

h2 > div.dropdown {
  display: inline-block;
  margin-top: -5px;
  margin-right: 10px;
}

.text-grey1 {
  color: $text-primary;
}

.text-grey2 {
  color: $text-secondary;
}

.text-grey3 {
  color: $text-tertiary;
}

.text-wrap {
  white-space:normal;
}

.page-header
{
  border-bottom: none;
  text-align: center;
}

.grey-box {
  background: $grey-bg-1;
}

.text-danger
{
  color: $error-color;
}

.multi-editor {
  input {
    width:100%;
  }
}

a.glyphicon {
  color: $text-primary-light;
  text-decoration: none;
  &:hover {
    color: $grey-bg-1;
    text-decoration: none;
  }
}

.valign {
  display: flex;
  align-items: center;
  justify-content: center;
}

/** A default 15px-es left és right padding kikapcsolása **/
.no-gutter {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.ufInputTextarea {
  position: relative;
  .counter {
    color: $text-secondary;
    position: absolute;
    top: -20px;
    right: 0;
  }
}

/************ </COMMON> *****************/
/************ <GAP - SPACER> *****************/
@for $i from 1 through 10 {
  .horz-sep-top#{$i*5} {
    margin-top: #{$i*5}px;
  }
  .horz-sep-btm#{$i*5} {
    margin-bottom: #{$i*5}px;
  }
  .padding-top#{$i*5} {
    padding-top: #{$i*5}px;
  }
  .padding-btm#{$i*5} {
    padding-bottom: #{$i*5}px;
  }

}
/************ </GAP - SPACER> *****************/
/************ <TABLE> *****************/
.table-striped
{
  > tbody{
    > tr {
      border: none;
      padding:.5em 1.2em;
      vertical-align: middle;
      &:nth-of-type(odd)
      {
        background-color: $grey-bg-2;
      }
      &:nth-of-type(even)
      {
        background-color: $grey-bg-1;
      }
      > td {
        border: none;
        padding: 1rem 1.2rem !important;
      }
    }
  }
}
.table-striped.table-hover {
  > tbody{
    > tr {
      border: none;
      padding: .5em 1.2em;
      vertical-align: middle;

      &:hover {
        background-color: rgba(0, 0, 0, .095) !important;
      }
    }
  }
}

.table {
  > thead {
    > tr {
      > th {
        background: $grey-bg-3;
        border-bottom: none;
        padding: 1.2em;
      }
    }
  }
  > tbody
  {
    > tr.table-with-border{
      > td {
        border-top: 1px solid $grey-bg-3;
        border-bottom: 1px solid $grey-bg-3;
      }
    }
  }
  > tbody.no-border
  {
    border: none;
  }
}
.table-wrapper {
  overflow-y:auto;
}

.hidden-element {
  display: none;
}

.icon-column {
  width:1%;
}

.border-table {
  & > tbody {
    > tr {
      border-top: 1px solid $grey-bg-3;

      &:first-child {
        border-top: none;
      }
    }
  }
}

table > tbody {
  > tr {
    > td {
      vertical-align: middle !important;
      > * {
        padding-left:0 !important;
        padding-right:0 !important;
        &[data-title] {
          display: none;
        }
      }
    }
  }
}
@mixin responsiveTable() {
  &, & > tbody, & > tbody > tr {
    display: block;
  }
  & {
    > thead {
      display:none;
    }
    > tbody {
      > tr {
        padding: 1rem 1rem 1rem 0;
        > td {
          border:none;
          padding: 1rem 0 0 0 !important;
          display: flex;
          display: -ms-flexbox;
          flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          width:auto;
          &:first-child {
            padding-top: 0 !important;
          }
          &:empty {
            display:none;
          }
          > *
          {
            padding-left: 1rem !important;
            padding-right: 1rem !important;
            &[data-title] {
              display: block;
              &:before {
                content: attr(data-title);
                color: $text-primary;
                font-weight:bold;
              }
            }
          }
        }
      }
    }
  }
}

.table-label {
  margin: 0 0 0px 0;
  color: $text-secondary;
  font-weight: normal;
  & + * {
    color: black;
  }
}
.table-value {
  color: $text-secondary;
  font-weight: bold;
}

@mixin tableValue {
  color: $text-secondary;
  font-weight: bold;
}

.table-value {
  @include tableValue;
}

@media only screen and (max-width : map-get($grid-breakpoints, sm)) {
  .responsive-table-sm {
    @include responsiveTable();
  }
  .table-value-sm {
    @include tableValue;
  }
}

@media only screen and (max-width : map-get($grid-breakpoints, md)) {
  .responsive-table-md {
    @include responsiveTable();
  }
  .table-value-md {
    @include tableValue;
  }
}
@media only screen and (max-width : map-get($grid-breakpoints, lg)) {
  .responsive-table-lg {
    @include responsiveTable();
  }
  .table-value-lg {
    @include tableValue;
  }
}
@media only screen and (max-width : map-get($grid-breakpoints, xl)) {
  .responsive-table-xl {
    @include responsiveTable();
  }
  .table-value-xl {
    @include tableValue;
  }
}

/************ </TABLE> *****************/
/************ <PANEL> *****************/
.panel {
  &.panel-default {
    border: 1px solid $grey-bg-3;
    background: $grey-bg-1;
    border-radius: 5px;
    .panel-body {
      padding:20px 30px;
    }
    .row {
      .form-check-label {
        color: $text-secondary;
        font-weight: normal;
      }
    }
  }
  &.panel-white {
    border: none;
    background: $grey-bg-0;
    .panel-body {
      padding:20px 30px;
    }
  }
}
/************ </PANEL> *****************/
/************ <FORM> *****************/
.form-horizontal {
  .control-label-uppercase {
    text-align: left;
    text-transform: uppercase;
    color: $text-primary;
    font-weight: bold;
  }
  .control-label,
  .col-form-label,
  .form-check-label{
    text-align:left;
    color: $text-secondary;
    font-weight: normal;
  }
  .form-control-static,
  .form-control-plaintext{
    color: $text-primary;
    &:empty:before {
      content: '-';
    }
  }
  .form-control-static-bold,
  form-control-plaintext-bold{
    color: $text-primary;
    font-weight: bold;
    padding-top: 7px;
    &:empty:before {
      content: '-';
    }
  }
  &.text-uppercase
  {
    .form-group {
      > label:first-child
      {
        text-transform: uppercase;
        font-weight:bold;
      }
    }
  }
}
.form-row, .row {
  label.text-uppercase {
    text-transform: uppercase;
    font-weight:bold;
  }
}
/************ </FORM> *****************/
/************ <ACCORDION> *****************/
.ui-accordion {
  .ui-accordion-tab {
    margin-bottom:20px;
    .ui-accordion-header {
      border-radius: 4px 4px 0 0;
      border: 1px solid $grey-sep-border;
      background: $grey-sep-border;
      &:hover {
        background: $grey-sep-border !important;
        color: $text-primary-light;
        border: 1px solid $grey-sep-border !important;
      }
      a {
        padding: .5em 1.4em .5em .8em;
        text-decoration: none !important;
        font-size:28px;
        color: $text-primary-light !important;

      }
      .fa-caret-down {
        transform: rotate(180deg);
        right: 1em;
        left: initial;
        top: 55%;
        &:before {
          @include contentImage('chevron_down_w.png');
        }
      }
      .fa-caret-right {
        right: 1em;
        left: initial;
        top: 45%;
        &:before {
          @include contentImage('chevron_down_w.png');
        }
      }
    }
    .ui-accordion-content {
      border-radius: 0 0 4px 4px;
      border: 1px solid $grey-bg-2;
    }
  }
}
/************ </ACCORDION> *****************/
/************ <ALERT> *********************/
.alert {
  text-align: center;
  color: $text-secondary;
  font-size: 20px;
  padding: 1.5em 0;
}
/************ </ALERT> *********************/
/************ <MESSAGES> *******************/
.warning-message {
  color: $text-secondary-light;
  background: $error-color;
  border-radius: 5px;
  padding: 5px;
  display: block;
  margin-top: 3px;
  position: relative;
  .warning-text {
    display: table-cell;
    padding-left: 10px;
  }
  .warning-icon {
    display: table-cell;
    vertical-align: middle;
    font-size: 10px;
  }
  .warning-text::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 10%;
    margin-left: -5px;
    border: 6px solid;
    border-color: transparent transparent $error-color transparent;
  }
}
.warning-message-hidden {
  display: none;
}

.help-message {
  color: $text-secondary-light;
  background: $otp-light-blue;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  .help-text {
    display: table-cell;
    padding: 5px;
    white-space: nowrap;
  }
  .help-icon {
    display: table-cell;
    vertical-align: top;
    padding-left: 5px;
    padding-top: 5px;
  }
  .help-text::after {
    content: "";
    position: absolute;
    border: 6px solid;
  }
}

@media screen and (min-width: map-get($grid-breakpoints, lg)) {
  .help-message-right {
    top: 15px;
    left: 100%;
    margin-left: -7px;
    min-width: 300px;
    min-height: 50px;
    .help-text::after {
      top: 20px;
      right: 100%;
      margin-top: -5px;
      border-color: transparent $otp-light-blue transparent transparent;
    }
  }
  .help-message-left {
    top: 15px;
    right: 100%;
    margin-right: -7px;
    min-width: 300px;
    min-height: 50px;
    .help-text::after {
      top: 20px;
      left: 100%;
      margin-top: -5px;
      border-color: transparent transparent transparent $otp-light-blue;
    }
  }
  .help-message-bottom {
    top: 100%;
    margin-top: 6px;
    .help-text::after {
      bottom: 100%;
      left: 10%;
      margin-left: -5px;
      border-color: transparent transparent $otp-light-blue transparent;
    }
  }
  .help-message-top {
    bottom: 100%;
    margin-bottom: 6px;
    .help-text::after {
      top: 100%;
      left: 10%;
      margin-left: -5px;
      border-color: $otp-light-blue transparent transparent transparent;
    }
  }
}

@media screen and (max-width: map-get($grid-breakpoints, lg)) {
  .help-message {
    top: 100%;
    margin-top: 6px;
    .help-text::after {
      bottom: 100%;
      left: 10%;
      margin-left: -5px;
      border-color: transparent transparent $otp-light-blue transparent;
    }
  }
}

.help-message-hidden {
  display: none;
}

.redStar {
  color: $error-color;
}

.presentationFreezed {
  color: $otp-orange;
}

/************ </MESSAGES> ******************/

/************ <CAROUSEL> *******************/
.carousel {
  padding: 0 50px;
  border: 1px $grey-bg-1 solid;
  * {
    outline:none;
  }

  .carousel__slider {
    overflow-y: auto;
    height: 600px;
  }

  .carousel__inner-slide {
    background: $grey-bg-0;
  }

  .carousel__dot-group {
    text-align: center;
    margin-bottom: 10px;
    button {
      background: white;
      border: 1px solid $grey-sep-border;
      border-radius: 15px;
      width: 15px;
      margin-right: 10px;
      height: 15px;
      box-shadow: 1px 1px 3px $grey-sep-border;
    }

    button.carousel__dot--selected {
      background: $otp-green;
    }
  }

  .carousel__next-button {
    position: absolute;
    top: 50%;
    height: 40px;
    width: 40px;
    border: 0;
    right: 0px;
    background: transparent;
  }
  .carousel__back-button {
    position: absolute;
    top: 50%;
    height: 40px;
    width: 40px;
    border: 0;
    left: 0px;
    background: transparent;
  }
}
/************ </CAROUSEL> *******************/
