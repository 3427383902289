/*!
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

/* primefaces specifikus design elemek */

@import 'utils';

.ui-widget-content {
  color: $text-primary;
}

.ui-inputtext
{
  height: 36px;
  padding: .375rem .75rem;
  box-shadow: none !important;
  border: 1px solid #c5c5c5;

  &:focus
  {
    border: solid 1px #52ae30 !important;
  }
}
.btn
{
  &.focus, &:focus{
    box-shadow: none !important;
  }
}
/********* <DROPDOWN> ***********/
.ui-dropdown
{
  .ui-dropdown-label
  {
    padding-right: 2em;
  }
  .ui-dropdown-trigger {
    border:none;
    .fa{
      margin-top: .4em;
      margin-left: -.6em;

    }
  }
}

.ui-dropdown-panel
{
  margin-top:-2px;
  padding-left:0px;
  padding-right:0px;
  box-shadow: none;
  .ui-dropdown-list{
    padding:0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .ui-dropdown-item
  {
    padding:12px;
    margin:0;
    border-radius:0;
    &.ui-state-highlight {
      background: $otp-yellow;
      color: $text-primary;
    }
  }
  .ui-dropdown-item:not(.ui-state-highlight):hover
  {
    background: $grey-bg-1;
  }
}

.fa-caret-down:before {
  @include contentImage('chevron_down.png');
}
.fa-caret-right:before {
  @include contentImage('chevron_down.png');
}
/********* </DROPDOWN> ***********/

/********* </CHECKBOX> ***********/
.ui-chkbox-label {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
}
.ui-chkbox-icon {
  background-color: $otp-green;
}
.ui-chkbox-box.ui-state-active {
  border-color: $otp-green;
}
/********* </CHECKBOX> ***********/
/********* <CALENDAR> ***********/
.ui-datepicker {
  @media only screen and (max-width : map-get($grid-breakpoints, sm))  {
    width: 250px;
  }
  @media only screen and (max-width : 320px)  {
    width: 210px;
  }
}
/********* </CALENDAR> ***********/

/********* <TABVIEW> ***********/
.ui-tabview.ui-tabview-top
{
  > .ui-tabview-nav{
    border:none;
    border-radius: 0;
    padding:0;
    display:flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    border-bottom: 1px solid $grey-sep-border;
    li {

    }
    li.ui-state-default
    {
      @media only screen and (min-width : map-get($grid-breakpoints, xs))
      {
        width:100%;
        a {
          padding: 6px 14px;
        }
        &:hover {
          border-bottom: 1px solid transparent !important;
        }
        &.ui-state-active {
          border-bottom: 1px solid transparent;
        }
      }
      @media only screen and (min-width : map-get($grid-breakpoints, sm))
      {
        a {
          padding: 0.5em 0.75em;
        }
        width:auto;
      }
      @media only screen and (min-width : map-get($grid-breakpoints, md))
      {
        a {
          padding: 1em 1.5em;
        }
        width:auto;
      }

      a {
        color: $text-primary;
      }
      &
      {
        border-radius: 0;
        border: 1px solid transparent;
        background:none;
        //border-bottom: 1px solid $grey-sep-border;
        margin:1px 0;
      }
      &:hover {
        border-top-left-radius: 4px !important;
        border-top-right-radius: 4px !important;
        border: 1px solid $grey-bg-3 !important;
        border-bottom:1px solid #fff !important;
        margin:1px 0;
      }
      &.ui-tabview-selected {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        font-weight: bold;
        border: 1px solid $grey-sep-border;
        border-bottom:0px solid #fff;
        margin:0px 0;
      }
      &.ui-state-active {
        border-bottom: 1px solid #fff;
      }

    }
    li:not(.ui-state-active):not(.ui-state-disabled):hover
    {
      border-radius: 0;
      border:none;
      background:none;
    }
  }
  .ui-tabview-panel {
    padding: 2em 0 0 0;
  }
}


/********* </TABVIEW> ***********/
/************ <PANEL> *****************/
.ui-panel {
    border-color: $grey-bg-3;
    background: $grey-bg-1;
    border-radius: 4px;
    .ui-panel-content {
      border-radius: 0 0 4px 4px;
      padding: 10px 20px;
    }
    .ui-panel-titlebar {
      text-transform: uppercase;
    }
    &.yellow {
      border-color: $otp-yellow;
      .ui-panel-titlebar {
        background: $otp-yellow;
        border-color: $otp-yellow;
        color: $text-primary-light;
      }
      .ui-panel-content {
        background: $grey-bg-0;
      }
    }

    &.purple {
      border-color: $otp-purple;
      .ui-panel-titlebar {
        background: $otp-purple;
        border-color: $otp-purple;
        color: $text-primary-light;

      }
      .ui-panel-content {
        background: $grey-bg-0;
        border-color: $otp-purple;
      }

    }
}
/************ </PANEL> *****************/
/************ <CALENDAR> *****************/
.ui-calendar {
  display: block;
  > input {
    width: 100%;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  > button {
    position: absolute;
    right: -1px;
  }
}
/************ </CALENDAR> *****************/
