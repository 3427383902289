/*!
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

/* alap komponens. */
/* project specifikus design elemek */
@import '~normalize.css/normalize'; /*stílusok visszaállítása alaphelyzetbe*/
@import '~open-iconic/font/css/open-iconic-bootstrap.css';
@import 'header_menu'; /* fejléc és menü design elemek*/
@import 'icons'; /* képek és ikonok */
@import 'pfMain'; /* primefaces specifikus design elemek */
@import 'scrMain'; /* Scriptumos stílus osztályok */
@import 'utils'; /* sass utility függvények */
@import 'popup'; /* popup stílusosztályok */

/************ <REMARK-FORM> *****************/
.remark-form {
  position:absolute;
  bottom:50px;
  left:0;
  width:100%;
  overflow: hidden;
}
/************ </REMARK-FORM> *****************/
/************ <ASSIGNMENT-BOX> *****************/

.img-fit {
  object-fit: contain;
  object-position: 50% 50%;
  height: 148px;
  max-width:100%;
}

.img-cover {
  object-fit: cover;
  object-position: 50% 50%;
  height: 100%;
  width:100%;
}
@media only screen and (max-width : map-get($grid-breakpoints, sm))
{
  .ui-button > .ui-button-text {
    white-space: normal;
  }
}

.assignment-box {
  border: 1px solid $grey-bg-2;
  border-radius: 4px;
  padding-left: 0;
  padding-right: 0;
  border-collapse: collapse;
  overflow: hidden;

  > div {
    height: 100%;
  }

  .box {
    border: 1px solid $grey-bg-2;
    border-bottom:none;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .additional {
    border: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    .ui-button {
      width:100%;
      border-radius: 0 0 4px 4px;
      border:1px solid $otp-green;
      margin:0;
    }
  }
  &.horizontal {
    background: $grey-bg-1;
    box-shadow: 10px 10px 5px rgba(238, 238, 238, .5);
    .additional {
      .ui-button {
        border-radius: 4px;
      }
    }

  }

  .image {
    margin-top: 0;
    margin-bottom: 0;
    border: none;
    height: 148px;
    > img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .address {
    min-height: 5rem;
    background-color: $grey-bg-2;
    margin-top: 0;
    margin-bottom: 0;

  }
  .price {
    vertical-align: middle;
    font-weight: bold;
    .defaultCurrency {
      font-weight: normal;
    }
  }
  .price,
  .groundspace,
  .roomnumber {
    min-height: 3rem;
    margin-top: 0;
    margin-bottom: 0;
    background: $grey-bg-0;
  }
  .groundspace {
    border-right: none;
  }

  .row, .col-1, .col-sm-1, .col-md-1, .col-lg-1, .col-2, .col-sm-2, .col-md-2, .col-lg-2, .col-3, .col-sm-3, .col-md-3, .col-lg-3, .col-4, .col-sm-4, .col-md-4, .col-lg-4, .col-5, .col-sm-5, .col-md-5, .col-lg-5, .col-6, .col-sm-6, .col-md-6, .col-lg-6, .col-7, .col-sm-7, .col-md-7, .col-lg-7, .col-8, .col-sm-8, .col-md-8, .col-lg-8, .col-9, .col-sm-9, .col-md-9, .col-lg-9, .col-10, .col-sm-10, .col-md-10, .col-lg-10, .col-11, .col-sm-11, .col-md-11, .col-lg-11, .col-12, .col-sm-12, .col-md-12, .col-lg-12 {
    //padding-left: 0px !important;
    //padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .assignment-sold {
    color: $grey-bg-0;
    background: $grey-background;
    position: absolute;
    font-weight: bold;
    text-align: center;
    padding: 8px 30px;
    border-top: 1px solid $grey-bg-2;
    border-bottom: 1px solid $grey-bg-2;
    top: 50%;
    left: 0;
    right:0;
    @include transition-transform(0,-50%);
  }

}

.ui-accordion {
  .ui-accordion-tab:first-child { //TODO: "highlight" stílust használni ha lesz megoldás, addig marad a first-child
    .ui-accordion-header {
      border: 1px solid $text-primary;
      background: $text-primary;
      &:hover {
        background: $text-primary !important;
        border: 1px solid $text-primary !important;
      }
    }
  }
}
/************ </ASSIGNMENT-BOX> *****************/
/************ <AGENT> *****************/
.word-break {
  @media only screen and (max-width : map-get($grid-breakpoints, sm))
  {
    word-break:break-all;
    word-wrap: break-word;
  }
}

.agent-box {
  border: 2px solid $grey-bg-2;
  border-radius: 4px;
  padding-left: 0;
  padding-right: 0;
  border-collapse: collapse;

   .img-box {
    //height: 100%;
     img {
      max-width: 60px;
      max-height: 60px;
      clip-path: circle(30px at center);
    }
  }

  .box {
    text-align: left;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .agent {
    text-align: left;
    min-height: 5rem;
    background-color: $grey-bg-2;
    margin-top: 0;
    margin-bottom: 0;
  }
  .agent-img {
    height:100px;
    width:100px;
    overflow: hidden;
  }

  .question,
  .phone {
    font-weight: bold;
  }

  @media only screen and (min-width : map-get($grid-breakpoints, sm))
  {
      hr {
        margin-right: -128px;
      }
  }
}

.agent-box-nameAndPhone {
  background-color: $grey-bg-2;
  min-height: 3em;
  font-size: 13px;
  div {
    border: none;
  }
  .phone {
    font-weight: bold;
    vertical-align: middle;
  }
}
/************ </AGENT> *****************/
/************ <BUYPRESENTATION> *****************/
@media screen and (max-width: 480px) {
  .buy-mobile {
    width: 100%;
  }
}

.buy-presentation-box {
  padding: 0px;
  > div {
    padding: 20px 10px 20px 10px;
  }
  .number-index {
    color: $text-primary;
    background: $grey-bg-0;
    position: absolute;
    width: 30px;
    font-weight: bold;
    text-align: center;
    padding: 5px 0px;
    margin-top: 0;
    margin-left: 0;
    top:0;
    left:0;
  }
  .address.box,
  .price.box,
  .groundsoace.box,
  .roomnumber.box,
  .agent-box-nameAndPhone,
  .presentation-date{
    padding: 10px 6px;
  }
  .presentation-date {
    color: $text-secondary;
    font-size: 15px;
    vertical-align: middle;
    i {
      padding-top: 3px;
      cursor: pointer;
    }
  }
}

.buy-offer-currency {
  text-align: right;
}

.buy-offer-currency-bold {
  text-align: right;
  font-weight: bolder;
}

/************ </BUYPRESENTATION> *****************/
/************ <BUYBUYOFFER> *****************/
.buy-buyoffer-box {
  padding: 0px;
  > div {
    padding: 20px 10px 20px 10px;
  }
  .number-index {
    color: $text-primary;
    background: $grey-bg-0;
    position: absolute;
    width: 30px;
    font-weight: bold;
    text-align: center;
    padding: 5px 0px;
    margin-top: 0;
    margin-left: 0;
    top:0;
    left:0;
  }
  .address.box,
  .price.box,
  .groundsoace.box,
  .roomnumber.box,
  .agent-box-nameAndPhone,
  .buyoffer-date{
    padding: 10px 6px;
  }
  .buyoffer-date {
    color: $text-secondary;
    font-size: 15px;
    vertical-align: middle;
    i {
      padding-top: 3px;
      cursor: pointer;
    }
  }
}
.sort-dropdown {
  @media only screen and (max-width : map-get($grid-breakpoints, sm))
  {
    width:100% !important;
  }
}
/************ </BUYBUYOFFER> *****************/
/************ <BUYOFFER> ***************/
.buyOffer-box {
  border: 1px solid $grey-bg-3;
  border-radius: 4px;
  margin-bottom: 30px;

  .buyOffer-box-header {
    border-radius: 4px 4px 0 0;
    border: 1px solid $grey-background;
    background: $grey-background;
    padding: .3em .8em .3em .8em;
    color: $text-primary-light;
    font-size: 24px;
  }

  .buyOffer-box-secondary-header {
    border-radius: 4px 4px 0 0;
    border: 1px solid $grey-sep-border;
    background: $grey-sep-border;
    padding: .3em .8em .3em .8em;
    color: $text-primary-light;
    font-size: 22px;
  }

  .buyOffer-box-body {
    padding: 20px 20px;

    > div {
      margin: 5px 0px 15px 0px;
    }

    .details-link {
      color: $otp-green;
      &:hover {
        color: $otp-green;
        cursor: pointer;
      }
    }
    .form-control-plaintext {
      color: $text-secondary;
    }

    table.responsive-table-sm {
      background: $grey-bg-1;
    }
  }

  table {
    > tbody {
      > tr {
        > td {
          padding-bottom: 3px;
          padding-top: 3px;
          color: $text-secondary;
          background: $grey-bg-1;
          border: none;
        }
        .boldText {
          font-weight: bold;
        }
        &:first-child > td {
          padding-top: 20px;
        }
        &:last-child > td {
          padding-bottom: 20px;
        }
      }
    }
  }

  .paymentScheduleTable {
    width: 90%;
    margin-bottom: 0;
    > tbody {
      > tr {
        &:first-child > td, &:last-child > td {
          padding-bottom: 3px;
          padding-top: 3px;
        }
      }
    }
  }

  .buttons-box {
    padding-top: 20px;
    text-align: center;
    > button {
      margin: 0px 5px 0px 0px;
      width: 190px;
    }
  }
  .paymentSchedule {
    line-height: 17px;
    margin-top: 1em;
    .schedule {
      font-weight: bold;
      float: left;
    }
  }
  .paymentSchedule:first-child {
    margin-top: 0px;
  }
}

/************ </BUYOFFER> **************/
/************ <CONTRACTMODIFICATION> ***************/
.contractModificaton-box {
  padding: 20px;
}
/************ </CONTRACTMODIFICATION> **************/
/************ <BUYOFFERCOMPARSION> *****/
.buyoffer-comparsion-page {
  table {
   > tbody {
     > tr {
       > th {
          background: $grey-bg-2;
          border-top: none;
          border-bottom: 1px solid white;
          padding: 1.2em;
        }
       td {
          background: $grey-bg-1;
          border-top: none;
          border-bottom: 1px solid white;
          border-left: 1px solid white;
        }
      }
    }
  }
  .blue-box {
    color: $text-primary-light;
    background: $otp-light-blue;
    border-radius: 3px;
    padding: 10px 20px 10px 20px;
    margin: 20px 0px 20px 0px;
    .blue-box-text {
      display: table-cell;
      padding-left: 15px;
    }
    .blue-box-icon {
      display: table-cell;
      vertical-align: middle;
    }
  }
  .buttons-box {
    text-align: center;
    > button {
      display: block;
      margin: 5px auto;
      width: 130px;
    }
  }
  .paymentSchedule {
    line-height: 17px;
    margin-top: 1em;
    .schedule {
      text-align: left !important;
      font-weight: bold;
    }
  }
  .paymentSchedule:first-child {
    margin-top: 0px;
  }
  .assignment-box .image {
    height:100%;
    max-height:180px;
  }
  @media only screen and (max-width : 400px)
  {
    .assignment-box .image {
      height:100%;
      max-height:none;
    }
  }
}
/************ </BUYOFFERCOMPARSION> *****/
/************ <NEWBUYOFFER> *****/
.newBuyOffer {
  .newBuyOffer-box {
    border: 1px solid $grey-bg-3;
    border-radius: 4px;
    margin: 10px 0;

    .newBuyOffer-box-primary-header {
      border-radius: 4px 4px 0 0;
      border: 1px solid $grey-background;
      background: $grey-background;
      padding: .3em .8em .3em .8em;
      color: $text-primary-light;
      font-size: 24px;
    }

    .newBuyOffer-box-secondary-header {
      border: 1px solid $grey-sep-border;
      background: $grey-sep-border;
      padding: .3em .8em .3em .8em;
      margin: 20px 0;
      color: $text-primary-light;
      font-size: 22px;
    }

    .newBuyOffer-box-body {
      padding: 20px 20px;

      .radioButtonLabel {
        margin-left: 30px;
      }

      .dynamic-box {
        padding-top: 20px;
      }

      .scheduleTitle {
        margin: 0 0 0px 0;
        color: $text-secondary;
        font-weight: normal;
      }

      .new-schedule-link {
        color: $otp-green;
        &:hover {
          color: $otp-green;
          cursor: pointer;
        }
      }
      .new-schedule-link-hidden {
        display: none;
      }
      label.text-uppercase {
        display:inline;
      }
    }
  }
  .grey-box {
    background: $grey-bg-1;
    border-radius: 3px;
    padding: 10px 20px;
    //margin: 10px 0 0 0;
    .grey-box-text {
      display: table-cell;
      padding-left: 10px;
      .grey-box-extra-text {
        padding-left: 5px;
        font-weight: bold;
        font-style: italic
      }
    }
    .grey-box-icon {
      display: table-cell;
      vertical-align: middle;
      color: $error-color;
      font-size: 12px;
    }
    .grey-box-icon-blue {
      display: table-cell;
      vertical-align: middle;
      color: $otp-light-blue;
      font-size: 12px;
    }
  }
  .box-indent-0 {
    padding: 0px 25px;
    margin: 0px 0px;
  }
  .box-indent-1 {
    padding: 0px 35px;
    margin: 0px 0px;
  }
  .buttons-box {
    margin: 20px 0 20px 0;
  }
}

.align-right {
  text-align: right;
}
/************ </NEWBUYOFFER> *****/

/************ <HOME VIEW> *****************/
.home-message
{
  text-align: center;
}

.home-box {
  margin: 25px auto;
  float: none;
  width: 300px;
  height: 260px;
  border: 2px solid $grey-bg-3;
  border-radius: 5px;

  > h3 {
    text-align: center;
  }

  > div {
    margin: 15px auto;
    text-align: center;
    padding-bottom: 20px;
  }

  .greyed {
    color: $grey-bg-3;
  }

  .home-badge {
    display: inline-block;
    color: $text-primary-light;
    background: $otp-light-blue;
    border-radius: 3px;
    padding: 1px 5px 1px 5px;
  }
}

.home-buy-logo {
  display: block;
  text-indent: -9999px;
  width: 64px;
  height: 64px;
  background: url('../images/buy_logo.svg');
  background-size: 60px 60px;
}

.home-sell-logo {
  display: block;
  text-indent: -9999px;
  width: 64px;
  height: 64px;
  background: url('../images/sell_logo.svg');
  background-size: 60px 60px;
}
/************ </HOME VIEW> *****************/

/************ <NOTIFICATION> *****************/
.notification-box {
  margin-top: 30px;
  color: $text-secondary;
  .green-link {
    color: $otp-green;
  }
}

.icon-column {
  width:1%;
}
.notification-list {
  margin-bottom: 20px;
  table {
    tr {
      height: 50px;
      .text-nowrap-sm
      {
        white-space: nowrap;
      }
      @media only screen and (max-width : map-get($grid-breakpoints, sm))
      {
        .text-nowrap-sm
        {
          white-space: normal;
        }
      }

    }
  }
}
/************ </NOTIFICATION> *****************/

/************ <EVENT PANEL> *****************/
.event-header-primary {
  color: $text-primary-light;
  background: $grey-background;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  .ui-dropdown {
    display:block;
  }
}

.event-header-secondary {
  @extend .event-header-primary;
  background: $grey-sep-border;

}

.buy-event-panel-buy {
  $color_border: $otp-purple;
  position:relative;
  padding-left: 2em; /* line-height of .event-panel-inside div:first-child span */

  margin: 30px 0px 20px 0px;
  border: 2px solid $color_border;
  border-radius: 6px;
  overflow: hidden;
  background: $grey-bg-1;

  .event-panel-inside {
    display:block;
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    width:2em; /* line-height of .wrapper div:first-child span */
    overflow:hidden;
    white-space:nowrap;
    background-color: $color_border;

    .title {
      -moz-transform-origin:0 50%;
      -moz-transform:rotate(-90deg) translate(-50%, 50%);
      -webkit-transform-origin:0 50%;
      -webkit-transform:rotate(-90deg) translate(-50%, 50%);
      -o-transform-origin:0 50%;
      -o-transform:rotate(-90deg) translate(-50%, 50%);
      -ms-transform-origin:0 50%;
      -ms-transform:rotate(-90deg) translate(-50%, 50%);
      transform-origin:0 50%;
      transform:rotate(-90deg) translate(-50%, 50%);
      position:absolute;
      top:0;
      bottom:0;
      height:2em; /* line-height of .wrapper div:first-child span */
      margin:auto;
      line-height: 2em; /* Copy to other locations */

      color: $text-primary-light;
      text-transform: uppercase;
    }
  }

  .content {
    .content-right {
      //margin: 20px 20px 20px 20px;
    }
  }
}

.buy-event-panel-presentation {
  $color_border: $otp-yellow;
  position:relative;
  padding-left: 2em; /* line-height of .event-panel-inside div:first-child span */

  margin: 30px 0px 20px 0px;
  border: 2px solid $color_border;
  border-radius: 6px;

  background: $grey-bg-1;
  overflow: hidden;

  .event-panel-inside {
    display:block;
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    width:2em; /* line-height of .wrapper div:first-child span */
    overflow:hidden;
    white-space:nowrap;
    background-color: $color_border;

    .title {
      -moz-transform-origin:0 50%;
      -moz-transform:rotate(-90deg) translate(-50%, 50%);
      -webkit-transform-origin:0 50%;
      -webkit-transform:rotate(-90deg) translate(-50%, 50%);
      -o-transform-origin:0 50%;
      -o-transform:rotate(-90deg) translate(-50%, 50%);
      -ms-transform-origin:0 50%;
      -ms-transform:rotate(-90deg) translate(-50%, 50%);
      transform-origin:0 50%;
      transform:rotate(-90deg) translate(-50%, 50%);
      position:absolute;
      top:0;
      bottom:0;
      height:2em; /* line-height of .wrapper div:first-child span */
      margin:auto;
      line-height: 2em; /* Copy to other locations */

      color: $text-primary-light;
      text-transform: uppercase;
    }
  }

  .content {
    .content-right {
      //margin: 20px 20px 20px 20px;
    }
  }



}

.buy-event-panel-buy,
.buy-event-panel-presentation,
.sell-event-panel{
  .assignment-box {
    border:none;
    border-radius:0;
    .image {
      height: 200px;
      @media only screen and (min-width: map-get($grid-breakpoints, sm)) {
        height: 182px;
      }
      @media only screen and (min-width: map-get($grid-breakpoints, md)) {
        height: 230px;
      }
      @media only screen and (min-width: map-get($grid-breakpoints, lg)) {
        height: 210px;
      }
      @media only screen and (min-width: map-get($grid-breakpoints, xl)) {
        height: 182px;
      }
    }
  }

}

.buy-event-panel-assignment {
  $color_border: $otp-light-blue;
  position:relative;
  padding-left: 2em; /* line-height of .event-panel-inside div:first-child span */

  margin: 30px 10px 20px 10px;
  border: 2px solid $color_border;
  border-radius: 6px;

  background: $grey-bg-1;

  .event-panel-inside {
    display:block;
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    width:2em; /* line-height of .wrapper div:first-child span */
    overflow:hidden;
    white-space:nowrap;
    background-color: $color_border;

    .title {
      -moz-transform-origin:0 50%;
      -moz-transform:rotate(-90deg) translate(-50%, 50%);
      -webkit-transform-origin:0 50%;
      -webkit-transform:rotate(-90deg) translate(-50%, 50%);
      -o-transform-origin:0 50%;
      -o-transform:rotate(-90deg) translate(-50%, 50%);
      -ms-transform-origin:0 50%;
      -ms-transform:rotate(-90deg) translate(-50%, 50%);
      transform-origin:0 50%;
      transform:rotate(-90deg) translate(-50%, 50%);
      position:absolute;
      top:0;
      bottom:0;
      height:2em; /* line-height of .wrapper div:first-child span */
      margin:auto;
      line-height: 2em; /* Copy to other locations */

      color: $text-primary-light;
      text-transform: uppercase;
    }
  }

  .content {
    .content-right {
      //padding: 1em 2em 0em 0em;
      //margin: 20px 20px 20px 20px;
    }
  }
}

.sell-event-link {
  color: $otp-green;
  &:hover {
    color: $otp-green;
    cursor: pointer;
  }
}
.sell-event-link-hidden {
  display: none;
}

.sell-event-panel{
  .accordion-box {
    height:auto;
    @media only screen and (min-width : map-get($grid-breakpoints, sm)) {
      min-height: auto;
    }
    @media only screen and (min-width : map-get($grid-breakpoints, md)) {
      min-height: auto;
    }
    @media only screen and (min-width : map-get($grid-breakpoints, lg)) {
      min-height: 210px;
    }
    @media only screen and (min-width : map-get($grid-breakpoints, xl)) {
      min-height: 182px;
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, lg)) {
  .sell-event-panel {
    .sell-event-assignment-box {
      z-index: 2;
    }

    .accordion-box {
      margin-left: -60px;
      padding-left: 75px;
      margin-top: 0 !important;
      padding-top: 0 !important;
      z-index:1;
      margin-right:0px !important;
    }
  }
}

.sell-event-panel {
  .sell-event-assignment-box {
    padding-left:0;
    padding-right:0;
    z-index: 2;
  }

  .accordion-box {
    background: $grey-bg-1;
    border: 1px solid $grey-bg-2;
    border-radius: 6px;
    z-index:1;
    margin-top: -10px;
    padding-top: 20px;
    margin-right: 0px;
  }
}

.sell-event-panel {
  margin: 25px 0px 25px 0px;
  ul {
    list-style: none;
    padding-left:15px;
  }
  li::before {
    content: "•";
    display: block;
    float: left;
    font-size: 40px;
    margin-left: -18px;
    line-height: 18px;
  }

  .business-title-box {
    padding-right:38px;
  }

  .event-actual-changes-box {
    font-size: 12px;
    color: $text-primary-light;
    @media screen and (max-width: 400px) {
      width: 100%;
    }
  }

  .event-badge {
    display: inline-block;
    color: $text-primary;
    background: $grey-bg-0;
    border-radius: 3px;
    padding: 1px 5px 1px 5px;
  }

  .ui-accordion {
    .ui-accordion-tab {
      margin-top: 10px;
      border: 2px solid;
      border-radius: 4px;

      .ui-accordion-header {
        border-radius: 0px 0px 0px 0px;
        margin-top: 0px;
        a {
          padding: .5em 1.4em .5em .8em;
          font-size: 18px;
        }
      }
      .ui-accordion-content {
        border: none;
        > div {
          padding-bottom: 10px;
        }
      }

      &.sell-event-accordionTab-buy {
        $color_border: $otp-purple;
        border-color: $color_border;
        .ui-accordion-header {
          border-color: $color_border;
          background: $color_border;
          &:hover {
            background: $color_border !important;
            color: $text-primary-light;
            border: 1px solid $color_border !important;
          }
        }
        li::before {
          color: $color_border;
        }
      }
      &.sell-event-accordionTab-presentation {
        $color_border: $otp-yellow;
        border-color: $color_border;
        .ui-accordion-header {
          border-color: $color_border;
          background: $color_border;
          &:hover {
            background: $color_border !important;
            color: $text-primary-light;
            border: 1px solid $color_border !important;
          }
        }
        .ui-accordion-content {
          border-color: $color_border;
        }
        li::before {
          color: $color_border;
        }
      }
      &.sell-event-accordionTab-assignment {
        $color_border: $otp-light-blue;
        border-color: $color_border;
        .ui-accordion-header {
          border-color: $color_border;
          background: $color_border;
          &:hover {
            background: $color_border !important;
            color: $text-primary-light;
            border: 1px solid $color_border !important;
          }
        }
        .ui-accordion-content {
          border-color: $color_border;
        }
        li::before {
          color: $color_border;
        }
      }
    }
  }
}

.assignmentEventSummary {
  margin: 10px;

  ul {
    list-style: none
  }

  li::before {
    content: "•";
    display: block;
    float: left;
    margin-left: -18px;
    font-size: 30px;
    line-height: 14px;
  }

  .panel {
    background: $grey-bg-1;
    border: 1px solid;
    border-radius: 4px;
    .header {
      padding: .5em 1em .5em .8em;
      font-size: 18px;
    }
    .content {
      padding: 10px;
    }
    .showAllEvent-link {
      color: $otp-green;
      &:hover {
        color: $otp-green;
        cursor: pointer;
      }
    }
  }

  .sell-event-buy {
    $color_border: $otp-purple;
    border-color: $color_border;
    .header {
      color: $text-primary-light;
      text-transform: uppercase;
      border-color: $color_border;
      background: $color_border;
    }
    li::before {
      color: $color_border;
    }
  }
  .sell-event-presentation {
    $color_border: $otp-yellow;
    border-color: $color_border;
    .header {
      color: $text-primary-light;
      text-transform: uppercase;
      border-color: $color_border;
      background: $color_border;
    }
    li::before {
      color: $color_border;
    }
  }
  .sell-event-assignment {
    $color_border: $otp-light-blue;
    border-color: $color_border;
    .header {
      color: $text-primary-light;
      text-transform: uppercase;
      border-color: $color_border;
      background: $color_border;
    }
    li::before {
      color: $color_border;
    }
  }
}
/************ </EVENT PANEL> *****************/

/************ <DOWNLOADBUTTON> *****************/
.downloadButton-box {
  height: 50px;
  border-radius: 4px;
  border: 1px solid $grey-bg-2;
  display: inline-flex;
  width: 100%;
  max-width: 300px;
  line-height: 80%;
  overflow: hidden;

  > div, button {
    height: 50px;
  }

  .icon {
    width: 50px;
    height: 50px;
    text-align: center;
  }

  .title-box {
    background-color: $grey-bg-2;
    padding: 3px 10px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .title {
      font-weight: bold;
      font-size: 12px;
    }
    .fileProperties {
      font-weight: normal;
      font-size: 12px;
    }
    .fileDate {
      font-weight: bold;
      font-size: 12px;
    }
  }

  .arrow-button {
    background-color: $otp-green;
    border-radius: 0 4px 4px 0;
    border: 1px solid $otp-green;
    margin: 0;
  }
}
/************ </DOWNLOADBUTTON> *****************/

/************ <DOCUMENT-BOX> *****************/
.document-box {
  border: 1px solid $grey-bg-2;
  border-radius: 4px;
  padding-left: 0;
  padding-right: 0;
  border-collapse: collapse;
  > div {
    padding: 5px 10px 5px 10px;
  }
}
/************ </DOCUMENT-BOX> *****************/
/************ <OWNERANDAUTHORIZED> *****************/
.ownerAndAuthorized {
  > table {
    width: 100%;
    > thead {
      > tr {
        > th {
          border: none;
          padding: 1em 1.5em 0 0.5em;
          text-align: left;
          font-size: 16px;
        }
      }
    }
    > tbody {
      > tr {
        > td {
          border: none;
          padding: 0.8em 0 0 0.5em;
          vertical-align: middle;
          text-align: left;
          font-size: 16px;
        }
      }
    }
  }
  .buttons-box {
    margin: 2em 0 0.5em 0.5em;
    > button {
      margin-right: 1em;
    }
  }
}
/************ </OWNERANDAUTHORIZED> *****************/

/************ <PROFILE> ****************************/
.profile {
  .profile-box {
    border: 1px solid $grey-bg-3;
    border-radius: 4px;
    margin: 10px 0;

    .primary-header {
      border-radius: 4px 4px 0 0;
      border: 1px solid $grey-background;
      background: $grey-background;
      padding: .3em .8em .3em .8em;
      color: $text-primary-light;
      font-size: 24px;
    }
    .secondary-header {
      border: 1px solid $grey-sep-border;
      background: $grey-sep-border;
      padding: .3em .8em .3em .8em;
      margin: 20px 0;
      color: $text-primary-light;
      font-size: 22px;
    }
    .box-body {
      padding: 20px 0;

      .phone {
        margin-bottom: 10px;
        > span {
          vertical-align: top;
          > button {
            height: 44px;
          }
        }
      }
    }
  }
  .form-group > label
  {
    font-weight:bold;
  }
  .button {
    margin-top: 20px;
    margin-bottom: 20px;
    &.rightButton {
      text-align: right;
    }
  }
}
/************ </PROFILE> ****************************/

/************ <LOGIN> ****************************/
.login-forgot-link {
  cursor: pointer;
  cursor: hand;
  color: $otp-green;
}
/************ </LOGIN> ***************************/
/************ <CONFIRMATIONCHECKBOX> ***************************/
.confirmation-checkbox {
  padding-top: 10px;
  display: flex;
  align-items: center;
  > label {
    margin-right: 10px;
  }
}
/************ </CONFIRMATIONCHECKBOX> ***************************/
/************ <SMSPASSWORD> ***************/
.smsPassword-box {
  padding: 20px;
}
/************ </SMSPASSWORD> **************/
/************ <BUYSUBPROCESS> ***************/
.decisionPossibilities-box {
  > div {
    vertical-align: center;
  }
   .radioButtonLabel {
     margin-left: 30px;
   }
}
/************ </BUYSUBPROCESS> ***************/
/************ <CURRENCY> ***************/
.currency-box {
  padding: 20px;
  > div {
    vertical-align: center;
  }
  .radioButtonLabel {
    margin-left: 30px;
  }
}
.priceInDefaultCurrency {
  padding-left: 5px;
  font-weight: normal;
}
/************ </CURRENCY> **************/


/************ <PRESENTAION> **************/
.presentationWarning {
  font-weight: bold;
  color: $otp-light-blue;
}
/************ </PRESENTAION> **************/

/************ <BUY> **************/
.buyWarning {
  font-weight: bold;
  color: $otp-light-blue;
}
/************ </BUY> **************/

/************ <FILEUPLOAD> **************/
.fileUpload-box {
  &:focus {
    outline: none;
  }
}
/************ </FILEUPLOAD> **************/


