/*!
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

/* képek és ikonok */

@import 'utils';

//.icon-logout {
//  @include contentImage('exit.png');
//}


.icon-phone {
  color: $otp-green;
  font-size: 10px;
}

.icon-question-green {
  color: $otp-green;
  font-size: 16px;
  margin-left: 10px;
}

.icon-negative {
  color: $error-color;
}

.icon-warning {
  color: $otp-yellow;
}

.icon-positive {
  color: $otp-green;
}

.icon-chevron {
  color: $grey-sep-border;
}
