/*
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

@import 'utils'; /* sass utility függvények */

.mm-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: auto;
  display: none;
  background: rgba(0, 0, 0, .1);
}

.mm-popup--visible {
  display: block;
}

.mm-popup__close {
  position: absolute;
  top: 15px;
  right: 20px;
  padding: 0;
  width: 12px;
  height: 12px;
  cursor: pointer;
  outline: none;
  border: none;
  text-align: center;
  text-indent: -9999px;
  background: transparent url('../images/close.png') no-repeat center center;
  background-size: 100%;
  margin: 0;
}

.mm-popup__input {
  display: block;
  width: 100%;
  height: 30px;
  border-radius: 3px;
  background: #f5f5f5;
  border: 1px solid #e9ebec;
  outline: none;
  -moz-box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  font-size: 14px;
  padding: 0 12px;
  color: #808080;
}

.mm-popup__btn {
  border-radius: 3px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 10px;
  margin: 0;
  line-height: 32px;
  height: 32px;
  border: 1px solid #666;
  text-align: center;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  background: transparent;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
}

.mm-popup__btn--success {
  background-color: #27ae60;
  border-color: #27ae60;
  color: #fff;
}

.mm-popup__btn--danger {
  background-color: #c5545c;
  border-color: #c5545c;
  color: #fff;
}

.mm-popup__box {
  $popupWidth: 500px;
  width: $popupWidth;
  position: absolute;
  top: 10%;
  left: 50%;
  margin-left: ($popupWidth / 2 * -1);
  background: #fff;
  box-shadow: 0px 5px 20px 0px rgba(238, 238, 238, .5);
  border-radius: 4px;
  border: 1px solid #B8C8CC;
  overflow: hidden;
  z-index: 100;
}

.mm-popup__box__header {
  padding: 15px 20px 0;
}

.mm-popup__box__header__title {
  margin: 0;
  font-size: 20px;
  text-align: left;
  font-weight: 600;
}

.mm-popup__box__content {
  background: #fff;
  border: 1px solid $grey-bg-3;
  border-radius: 4px;
  margin: 20px 20px;
}

.mm-popup__box__body {
  position: relative;
  z-index: 2;
}

.mm-popup__box__body p {
  margin: 0 0 5px;
}

.mm-popup__box__footer {
  overflow: hidden;
  padding: 10px 20px;
}

.mm-popup__box__footer__center-space {
  text-align: center;
}

.mm-popup__box__footer__right-space {
  float: right;
}

.mm-popup__box__footer__right-space .mm-popup__btn {
  margin-left: 10px;
}

.mm-popup__box__footer__left-space {
  float: left;
}

.mm-popup__box__footer__left-space .mm-popup__btn {
  margin-right: 5px;
}

.mm-popup__box--popover {
  width: 300px;
  margin-left: -150px;
}

.mm-popup__box--popover .mm-popup__close {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
  outline: none;
  text-align: center;
  text-indent: -9999px;
  background: transparent url('../images/close.png') no-repeat center center;
  background-size: 100%;
  margin: 0;
  z-index: 3;
}

.mm-popup__box--popover .mm-popup__box__body {
  padding: 20px;
}

@media (max-width: 420px) {
  .mm-popup__box {
    width: auto;
    left: 10px;
    right: 10px;
    top: 10px;
    margin-left: 0;
  }

  .mm-popup__box__footer__left-space {
    float: none;
  }

  .mm-popup__box__footer__right-space {
    float: none;
  }

  .mm-popup__box__footer {
    padding-top: 30px;
  }

  .mm-popup__box__footer .mm-popup__btn {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
}
