/*!
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

/* sass utility függvények és változók */
/******** <VARIABLES> ************/
$imagesPath: '../images';

$otp-green: #52ae30; /* OTP zöld */
$otp-green-hover: #339900; /* OTP zöld hover */
$otp-orange: #ff6600; /* OTP narancs */
$otp-orange-hover: #da5100; /* OTP narancs hover */
$otp-purple: #791d81; /* OTP lila */
$otp-light-blue: #3399ca; /* OTP világoskék - KöGá: 3399cc volt eredetileg, de a Figma szerint 3399ca a jó*/
$otp-blue: #336699; /* OTP kék */
$otp-yellow: #ffcc00; /* OTP sárga */
$otp-turquoise: #009999; /* OTP türkiz */
$otp-orange: #ff6600; /** OTP narancs */


$text-primary: #4a4a4a; /* Elsődleges szöveg */
$text-secondary: #767676; /* Másodlagos szöveg */
$text-tertiary: #9e9e9e; /* Harmadlagos szöveg */
$text-primary-light: #fff; /* Elsődleges szöveg sötét háttéren */
$text-secondary-light: #e3e3e3; /* Másodlagos szöveg sötét háttéren */

$error-color: #ff435a; /*OTP UI Piros, tiltó szín */

$grey-background: #4a4a4a; /* sötét szürke háttér */
$grey-sep-border: #c5c5c5; /* Szürke szeparátorok, borderek, 4. szint */
$grey-bg-3: #e5e5e5; /* Szürke flekk, 3. szint */;
$grey-bg-2: #eee; /* Szürke flekk, 2. szint */;
$grey-bg-1: #f5f5f5; /* Szürke háttér, 1. szint */;
$grey-bg-0: #fff; /* Háttér fehér, 1. szint */;
/******** </VARIABLES> ************/

@mixin backgroundImage($image) {
  background-image: url("#{$imagesPath}/#{$image}") !important;
}

@mixin contentImage($image) {
  content: url("#{$imagesPath}/#{$image}");
  background-image: none !important;
  box-sizing: border-box;
  position: relative;
  top: 2px;
}
@mixin transition-transform($posX, $posY)
{
  transform: translate($posX, $posY);
  -moz-transform: translate($posX, $posY);
  -webkit-transform: translate($posX, $posY);
  -o-transform: translate($posX, $posY);
  -ms-transform: translate($posX, $posY);
}

@mixin flip-horizontal() {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: "FlipH";
}
