/*!
 * Copyright 2017 by Scriptum, Inc.,
 * Mályva utca 34, H-6771 Szeged, Hungary
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Scriptum, Inc. ("Confidential Information").  You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Scriptum.
 */

/* fejléc és menü design elemek*/

@import "~bootstrap/scss/bootstrap";
@import 'utils';

.header-wrapper
{
  margin-bottom:10px;

  a {
    color: white !important;
    text-decoration: none;
  }

  &.logon{
    .header .logo {
      text-align: left;
      .header-name {
      }
    }
  }
  .header-top
  {
    background: $text-primary;
    font-size: 14px;
    color: #fff;
    padding:9px 0 5px;

    .logout {
      font-size:16px;
      cursor: pointer;
      @include flip-horizontal();
    }

    .username
    {
      display: inline-block;
      vertical-align: top;

      a
      {
        margin-right: 10px;
      }
    }
  }
  .header
  {
    background: $otp-green;
    color: white;
    position: relative;
    text-align: center;
    padding: 10px 3px;

    .stamp
    {
      background: $otp-orange;
      padding: 2px 5px;
      position:absolute;
      top: -18px;
      left: 93px;
      color: #fff;
      font-size: 70%;
      z-index:1;
    }

    .logo{
      white-space:nowrap;
    }

    .logourl
    {
      background: url(../images/logo.png) no-repeat;
      height: 36px;
      width: 237px;
      border-right: 2px solid #64b948;
      padding-right:10px;
      display: inline-block;
      margin-right:10px;
      margin-left:0px;
    }

    .header-name
    {
      display: inline-block;
      margin-top:1px;
      vertical-align: top;
      margin-bottom: 1px;
      font-size: 24px;
      font-weight: 200;

      .header-bold
      {
        font-weight: 600;
      }
    }
    .a, a:hover
    {
      text-decoration: none;
    }
    .navbar
    {
      font-size: 18px;
      a:hover
      {
        border-bottom: 2px solid #A1DD97 !important;
      }
      &.navbar-dark .navbar-toggler
      {
        border-color: hsla(0,0%,100%,.6);
      }
      @media only screen and (max-width : map-get($grid-breakpoints, sm)) {
        .navbar-collapse {
          position: absolute;
          z-index: 1;
          right: 0px;
          top:43px;
          border-radius: 5px;
          .navbar-nav {
            background: #4c9e2e;
            text-align: right;
            padding: 10px;
            border-radius: 5px;
          }
        }
      }

    }
  }
}

@media only screen and (max-width : map-get($grid-breakpoints, sm))
{
  .header-wrapper .header .logo
  {
    white-space:normal;
    .logourl {
      border-right: none;
    }
  }
}

/*
@media only screen and (max-width : 768px){
  #header-wrapper .header .logo img
  {
    left:5px !important;
    height:60% !important;
    top:6.1em !important;
  }
  #header-wrapper .header .username
  {
    width:61%;
    a
    {
      color: #3c6b43 !important; //#005B29
      font-size:12px;
      line-height:1.2em;
    }
  }
}*/

@media only screen and (max-width : map-get($grid-breakpoints, sm))
{
  .otp-footer {
    height: 75px !important;
  }
}

.otp-footer
{
  background: $text-primary;
  color: #fff;
  font-size: 14px;
  padding:15px 0;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  overflow:hidden;
}

.header-separator
{
  //border-right: 1px solid white;
  margin:10px 10px;
}

.header-triangle-box {
  float: left;
  position: relative;
}

.header-active-triangle::after {
  bottom: -7px;
  content:'';
  display: block;
  height: 10px;
  left: 50%;
  position: absolute;
  width: 10px;
  z-index: 99;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid $otp-green;
}

.header-active-link {
  border-bottom: 2px solid #A1DD97;
}

